<template>
    <div class="game_task_login">
        <div class="task_login_div">
            <div v-if="isBinding" class="task_login01">
                <div class="task_login01_01">Twitter</div>
                <img src="../../assets/images/twitter_logo.jpg" class="task_login01_02"/>
            </div>
            <div v-if="isBinding" class="task_login02" @click="authorizeTwitter()"> Bind Twitter account </div>

            <div v-show="false" class="task_login03">
                <!-- <button class="task_login02" @click="loginTelegram">电报登录</button>-->

                <vue-telegram-login mode="callback" telegram-login="mintagametask_bot" @callback="yourCallbackFunction"/>
            </div>

            <div v-if="isLoading && !isBinding && !isNewUser" class="task_login04">
                <i class="el-icon-loading"/> Loading . . .
            </div>
            <div v-if="!isLoading && !isBinding" class="task_login05">
                Please go to Telegram app!
            </div>

            <div class="task_login06" v-if="isNewUser && isLoading && !isBinding">
                <div class="task_login06_00">Invite code</div>
                <div class="task_login06_01">
                    <input v-model="inviteID"/>
                </div>
                <div class="task_login06_02">
                    <div class="task_login06_021" @click="toLogin(false)">Skip</div>
                    <div class="task_login06_022" @click="toLogin(true)">Log on</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import request from "../../tools/request";
    import toolUtils from "../../tools/toolUtils";

    import vueTelegramLogin from '../../components/framing/TgLogin';

    export default {
        name: "Index",
        components: { vueTelegramLogin },
        data() {
            return {
                inviteID: null,
                isNewUser: false,
                isBinding: false,
                oauthToken: null,
                oauthVerifier: null,
                loadingNum: 0,
                isLoading: true,
            }
        },
        watch: {},
        async created() {
            if (sessionStorage.getItem("isBinding")) {
                if (sessionStorage.getItem("isBinding") === true || sessionStorage.getItem("isBinding") === "true") {
                    this.isBinding = true;
                } else {
                    this.isBinding = false;
                }
                sessionStorage.setItem("isBinding", this.isBinding);
            }
            if (window.location.href.indexOf("is_binding") >= 0 && window.location.href.indexOf("user_id") >= 0) {
                const urlData = await toolUtils.parseUrlParams(window.location.href);
                if (urlData.is_binding[0] === true || urlData.is_binding[0] === "true") {
                    this.isBinding = true;
                    sessionStorage.setItem("isBinding", this.isBinding);
                }
                if (urlData.user_id[0]) {
                    sessionStorage.setItem("userId", urlData.user_id[0]);
                }

                window.location.href = "https://task.mintagame.com/#/login";
            }
            if (window.location.href.indexOf("oauth_token") >= 0 && window.location.href.indexOf("oauth_verifier") >= 0) {
                if (sessionStorage.getItem("userId")) {
                    const parameter = await toolUtils.parseUrlParams(window.location.href.substring(0, window.location.href.length - 7));

                    // console.log("parameter: " + JSON.stringify(parameter));

                    this.oauthToken = parameter.oauth_token[0];
                    this.oauthVerifier = parameter.oauth_verifier[0];

                    // console.log("oauth_token: " + this.oauthToken);
                    // console.log("oauth_verifier: " + this.oauthVerifier);

                    let isVerifier = false;

                    if (sessionStorage.getItem("oauth_token") || sessionStorage.getItem("oauth_verifier")) {
                        if (!(sessionStorage.getItem("oauth_token") === this.oauthToken
                            || sessionStorage.getItem("oauth_verifier") === this.oauthVerifier)) {
                            isVerifier = true;
                        }
                    } else {
                        isVerifier = true;
                    }

                    // console.log("isVerifier: " + isVerifier);

                    if (isVerifier) {
                        sessionStorage.setItem("oauth_token", this.oauthToken);
                        sessionStorage.setItem("oauth_verifier", this.oauthVerifier);

                        const userId = sessionStorage.getItem("userId");

                        await request.twitterVerifier(this.oauthToken, this.oauthVerifier, userId).then((res) => {
                            // console.log(res);
                            // {
                            //     "code": 1,
                            //     "data": {
                            //         "oauth_token": [
                            //             "1826090700817182722-Aom29AGD5JqiTs9vwnA8yRFCM4Qfvt"
                            //         ],
                            //             "oauth_token_secret": [
                            //             "sOkWkFeb8oBTTegJgoBOWOvdXa4065UMF8XsQECWpbGMY"
                            //         ],
                            //             "user_id": [
                            //             "1826090700817182722"
                            //         ],
                            //             "screen_name": [
                            //             "shell7859"
                            //         ]
                            //     },
                            //     "message": "Success"
                            // }

                            if (res.code && parseInt(res.code) === 1) {
                                sessionStorage.setItem("twitterUserId", res.data.user_id[0]);
                                sessionStorage.setItem("twitterUserName", res.data.screen_name[0]);
                                sessionStorage.setItem("twitter_oauth_token", res.data.oauth_token[0]);
                                sessionStorage.setItem("twitter_oauth_token_secret", res.data.oauth_token_secret[0]);

                                this.$message.success("Binding successful!");

                                setTimeout(() => {
                                    this.isBinding = false;
                                    sessionStorage.setItem("isBinding", this.isBinding);

                                    // this.$router.push("/home");
                                    window.location.href = "https://task.mintagame.com/#/home";
                                }, 1000);
                            } else {
                                this.$message.warning(res.message);

                                this.isBinding = false;
                                sessionStorage.setItem("isBinding", this.isBinding);

                                // this.$router.push("/home");
                                window.location.href = "https://task.mintagame.com/#/login";
                            }
                        }).catch((err) => {
                            this.$message.error(err.message);

                            this.isBinding = false;
                            sessionStorage.setItem("isBinding", this.isBinding);

                            // this.$router.push("/home");
                            window.location.href = "https://task.mintagame.com/#/login";
                        })
                    }
                } else {
                    this.$message.warning("Account data error!");

                    this.isBinding = false;
                    sessionStorage.setItem("isBinding", this.isBinding);

                    // this.$router.push("/home");
                    window.location.href = "https://task.mintagame.com/#/login";
                }
            }
        },
        mounted() {
            setTimeout(() => {
                if (!this.isBinding) {
                    this.onLogin();
                }
            }, 1000);
        },
        destroyed() {
        },
        methods: {
            async authorizeTwitter() {
                await request.twitterLogin().then((res) => {
                    // console.log(res);

                    window.location.href ="https://api.twitter.com/oauth/authenticate?oauth_token="+ res.data.oauth_token[0];
                }).catch((err) => {
                    this.$message.error(err.message);
                });
            },
            async onLogin() {
                let Telegram = window["Telegram"];

                if (!Telegram) {
                    this.loadingNum += 1;

                    if (this.loadingNum >= 5) {
                        this.isLoading = false;
                    } else {
                        setTimeout(() => {
                            if (!this.isBinding) {
                                this.onLogin();
                            }
                        }, 1000);
                    }

                    return;
                } else {
                    this.isLoading = true;
                }

                if (Telegram.WebApp && Telegram.WebApp.initData) {

                    const tg = Telegram.WebApp;
                    if (!tg.isExpanded) {
                        tg.expand();
                    }

                    if (Telegram.WebApp.initDataUnsafe.start_param) {
                        this.inviteID = Telegram.WebApp.initDataUnsafe.start_param;
                    }

                    // 请求登录接口
                    await request.tgLogin(Telegram.WebApp.initData, this.inviteID).then((res) => {
                        if(res.code && parseInt(res.code) === 1) {
                            sessionStorage.setItem("userToken", res.data.token);
                            sessionStorage.setItem("userId", res.data.userId);
                            sessionStorage.setItem("userName", res.data.userName);

                            this.$message.success("Login succeeded!");
                            this.$router.push("/home");
                        } else {
                            this.$message.warning(res.message);
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    });

                    // const searchParams = new URLSearchParams(Telegram.WebApp.initData);
                    // const loginUser = JSON.parse(searchParams.get('user'));
                    //
                    // await request.getVerifyRegistration(loginUser.id).then(async (res) => {
                    //     this.isNewUser = !res.data;
                    //     if(res.data) {}
                    // }).catch((err) => {
                    //     this.$message.error(err.message);
                    // })

                } else {
                    this.loadingNum += 1;

                    if (this.loadingNum >= 5) {
                        this.isLoading = false;
                    } else {
                        setTimeout(() => {
                            if (!this.isBinding) {
                                this.onLogin();
                            }
                        }, 1000);
                    }

                    console.log("bot not set inline mode");
                }
            },
            async toLogin(val) {
                let Telegram = window["Telegram"];

                if (!Telegram) {
                    this.isLoading = false;

                    return;
                } else {
                    this.isLoading = true;
                }

                if (Telegram.WebApp && Telegram.WebApp.initData) {

                    const tg = Telegram.WebApp;
                    if (!tg.isExpanded) {
                        tg.expand();
                    }

                    if (val ? (this.inviteID && this.inviteID.length === 8) : true) {
                        // 请求登录接口
                        await request.tgLogin(Telegram.WebApp.initData, this.inviteID).then((res) => {
                            if(res.code && parseInt(res.code) === 1) {
                                sessionStorage.setItem("userToken", res.data.token);
                                sessionStorage.setItem("userId", res.data.userId);
                                sessionStorage.setItem("userName", res.data.userName);

                                this.$message.success("Login succeeded!");
                                this.$router.push("/home");
                            } else {
                                this.$message.warning(res.message);
                            }
                        }).catch((err) => {
                            this.$message.error(err.message);
                        });
                    } else {
                        this.$message.warning("Invitation code error");
                    }
                } else {
                    this.isLoading = false;

                    console.log("bot not set inline mode");
                }
            },



            // ---------------------------------------------------------------------------------------------------------

            async loginTelegram() {
                //这里唯一要做的就是把你机器人参数传入进去（获取机器人token哪里可以看到）
                // await window.Telegram.Login.auth({ bot_id: '7265452603', request_access: true }, (data) => {
                //
                //     console.log(data, '这是回调数据');
                //
                //     if (!data) {
                //         // 失败时你需要做的逻辑
                //     } else {
                //         // 电报登录成功你需要做的逻辑
                //         setTimeout(() => {
                //             // this.$router.push("/home");
                //             window.location.href = "https://task.mintagame.com/#/home";
                //         }, 1000);
                //     }
                //
                // });

                // window.location.href = "https://task.mintagame.com/#/home";
            },

            yourCallbackFunction(user) {
                // console.log(user);
                // //我的业务逻辑
                // if (user) {
                //     console.log("user: " + user);
                //
                //     setTimeout(() => {
                //         // this.$router.push("/home");
                //         window.location.href = "https://task.mintagame.com/#/home";
                //     }, 1000);
                // }
            },
        }
    }
</script>

<style scoped lang="less">
    .game_task_login {
        background-color: #131313;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .task_login_div {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-evenly;
            height: 70%;
            min-height: 350px;
            max-height: 700px;
            .task_login01 {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
                .task_login01_01 {
                    font-size: 50px;
                    font-weight: 600;
                    color: white;
                }
                .task_login01_02 {
                    margin-top: 15px;
                    border-radius: 10%;
                    border: 1px solid white;
                    width: 8%;
                    min-width: 100px;
                    max-width: 150px;
                }
            }
            .task_login02 {
                border-radius: 10px;
                border: 0.5px solid white;
                width: 350px;
                height: 50px;
                color: white;
                font-size: 16px;
                /*font-weight: 600;*/
                line-height: 50px;
                text-align: center;
                cursor: pointer;
            }
            .task_login03 {
                width: 350px;
                color: white;
            }
            .task_login04 {
                font-size: 30px;
                font-weight: 600;
                color: white;
            }
            .task_login05 {
                font-size: 30px;
                font-weight: 600;
                color: white;
            }
            .task_login06 {
                .task_login06_00 {
                    font-size: 38px;
                    font-weight: 600;
                    color: white;
                }
                .task_login06_01 {
                    input {
                        width: 200px;
                        height: 50px;
                        font-size: 35px;
                        color: #027d93;
                    }
                }
                .task_login06_02 {
                    .task_login06_021 {
                        width: 200px;
                        height: 35px;
                        line-height: 30px;
                        text-align: center;
                        font-size: 16px;
                        font-weight: 600;
                        color: white;
                        cursor: pointer;
                        border: white solid 1px;
                        background-color: #393939;
                    }
                    .task_login06_022 {
                        width: 200px;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        font-size: 16px;
                        font-weight: 600;
                        color: white;
                        background-color: #027d93;
                        cursor: pointer;
                        border-left: white solid 1px;
                        border-right: white solid 1px;
                        border-bottom: white solid 1px;
                    }
                }
            }
        }
    }
</style>
